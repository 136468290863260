<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import auth from "./core/auth";

export default {
  created(){
    var userInfo = auth.getUserInfo();
    auth.applyAuthorizationHeader(userInfo?.token);
  }
};
</script>

<style lang="less">
@import "./styles/variaveis";

html, body, #app{
height: 100%;
}
body {
  background-color: #f9f9f9 !important;
  
}

#app {
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: @default-text-color;
}

.hide-external-buttons .plcht_btns_channels, .hide-external-buttons .rdstation-popup-position-bottom_right{
  display: none;
}

#nav {
  padding: 30px;
}

.navbar.navbar-dark {
  background-color: @dark-color;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.menu-superior-fix{
  padding-top: 95px;
}

.small-gutters {
  margin-right: -7px;
  margin-left: -7px;
}

.small-gutters > .col,
.small-gutters > [class*="col-"] {
  padding-right: 7px;
  padding-left: 7px;
}

h2 {
  font-weight: 900 !important;
  font-size: 28px !important;
  color: #333333 !important;
}

.link-veja-mais {
  color: @primary-color;
  font-weight: 700;
}

.link-veja-mais:hover {
  color: @primary-color;
}

h2.text-success {
  color: @primary-color !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: @primary-color !important;
  background-color: @primary-color !important;
}

input[type="checkbox"]:checked + label {
  color: @primary-color;
}

input.form-control,
select.form-control {
  border-radius: @border-radius;
}

.input-group-text {
  border-top-left-radius: @border-radius !important;
  border-bottom-left-radius: @border-radius !important;
}

.input-group .input-group-text{
   border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.custom-select {
  border-top-right-radius: @border-radius !important;
  border-bottom-right-radius: @border-radius !important;
}

// .input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child){
//   border-top-right-radius: 0 !important;
//   border-bottom-right-radius: 0 !important;
// }

// .input-group > .form-control:not(:last-child),
// .input-group > .custom-select:not(:last-child) {
//   border-top-right-radius: 0 !important;
//   border-bottom-right-radius: 0 !important;
// }

// .input-group > .input-group-append > .btn,
// .input-group > .input-group-append > .input-group-text,
// .input-group > .input-group-prepend:not(:first-child) > .btn,
// .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
// .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
// .input-group
//   > .input-group-prepend:first-child
//   > .input-group-text:not(:first-child) {
//   border-top-left-radius: 0 !important;
//   border-bottom-left-radius: 0 !important;
// }

.texto-destaque{
  color: @secondary-color !important;
  font-weight: bold;
}

.badge.anuncio-destaque {
  background-color: @secondary-color;
  color: white;
  font-size: 10px;
  font-weight: normal;
  padding: 3px 10px;
}

#app .text-primary{
  color:  @primary-color !important;
}

.btn.btn-secondary{
  color: #fff;
  background-color: @secondary-color;
  border-color: @secondary-color;
}

.btn.btn-yellow {
  background: #f2c94c;
  border-color: #f2c94c;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.18);
  border-radius: @border-radius;
  font-size: 14px;
}

.btn.btn-primary {
  color: #fff;
  background-color: @primary-color;
  border-color: @primary-color;
}

.btn.btn-primary:hover {
  background-color: @primary-color;
  border-color: @primary-color;
  opacity: 0.9;
}

.btn.btn-primary:disabled {
  background-color: @primary-color;
  border-color: @primary-color;
  opacity: 0.5;
  cursor: not-allowed;
}

.btn.btn-outline-primary {
  color: @primary-color;
  border-color: @primary-color;
}
.btn.btn-outline-primary:hover {
  color: white;
  background-color: @primary-color;
  border-color: @primary-color;
}
.btn.btn-outline-primary:disabled {
  border-color: @primary-color;
  color: @primary-color;
  opacity: 0.5;
  cursor: not-allowed;
}

.btn.btn-light-gray {
  background-color: @light-gray-color;
  border: 1px solid @medium-gray-color;
  border-radius: @border-radius;
  // color: #495057;
}

.fonte-numero {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

// Swiper Gallery
.swiper-pagination .swiper-pagination-bullet {
  border: 1px solid white;
  width: 10px;
  height: 10px;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #28a745;
}

// .card{
//    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
// }

// .collapsed .when-opened,
// :not(.collapsed) .when-closed {
//   display: none;
// }

.collapse-flip-icon {
  transition: 161ms ease-in;
  display: inline-block;
  transform-origin: 50% 50%;
}

[aria-expanded="true"] .collapse-flip-icon {
  transform: rotate(180deg);
}

.pointer {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .col-xl-1-of-5 {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

.border-radius{
  border-radius: @border-radius !important;
}

.title-case:first-letter {
    text-transform: uppercase;
}

/* ---------------------------------
Animation
-----------------------*/
@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.wiggle {
  display: inline-block;
  animation: wiggle 1.618s infinite;
}

/* ---------------------------------
VueSelect
-----------------------------------*/
#app .vs__dropdown-toggle {
  padding: 0.25rem 0;
  border-radius: 10px;
  border: 1px solid #ced4da;
  max-height: 38px;
}
#app .is-invalid .vs__dropdown-toggle {
  border-color: #dc3545;
}

#app .vs__selected {
  white-space: nowrap;
}

#app .vs__selected-options {
  overflow: hidden;
}


</style>
