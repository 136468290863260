import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatMoney', function(value) {
    if (value) {
        return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' });
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
});

Vue.filter('formatPhone', function(value) {
    if (value) {
        //retira os caracteres indesejados...
        value = value.replace(/[^\d]/g, "");

        var ddd = value.substr(0, 2);
        var numPart1 = value.substr(2, value.length - 6);
        var numPart2 = value.substr(value.length - 4);
        return `(${ddd}) ${numPart1}-${numPart2}`;
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
    }
});

Vue.filter('formatLocalDateTime', function(value) {
    if (value) {
        return moment.utc(String(value)).local().format("DD/MM/YYYY HH:mm")
    }
});

Vue.filter('formatLocalDate', function(value) {
    if (value) {
        return moment.utc(String(value)).local().format("DD/MM/YYYY")
    }
});

Vue.filter('formatCpf', function(value) {
    if (value) {
        //retira os caracteres indesejados...
        value = value.replace(/[^\d]/g, "");

        //realizar a formatação...
        var cpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

        return cpf;
    }
});