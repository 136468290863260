<template>
  <footer class="bg-dark py-5 text-center text-md-left">
    <div class="container">
      <div class="row">
        <div class="col-md mb-5">
          <nav class="nav flex-column">
            <router-link class="nav-link" to="/">Home</router-link>
            <router-link class="nav-link" to="/pesquisa">Quero comprar</router-link>            
            <router-link class="nav-link" to="/dashboard/meus-anuncios">Quero vender</router-link>            
            <router-link class="nav-link" to="/faq">Dúvidas frequentes</router-link>
            <a class="nav-link" href="http://blog.bikeregistrada.com.br/">Blog</a>
            <router-link class="nav-link" to="/dashboard">Acessar minha conta</router-link>
          </nav>
          <div class="redes-sociais mt-3">
            <div>
              <strong>Acesse e curta nossas redes sociais</strong>
            </div>

            <!-- <nav class="nav redes-sociais justify-content-center"> -->
            <a class="text-light mr-3" :href="config.URL_INSTAGRAM" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
            <a class="text-light mr-3" :href="config.URL_FACEBOOK"  target="_blank">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a class="text-light mr-3" :href="config.URL_YOUTUBE"  target="_blank">
              <i class="fab fa-youtube"></i>
            </a>
            <a class="text-light mr-3" :href="config.URL_TWITTER"  target="_blank">
              <i class="fab fa-twitter-square"></i>
            </a>
          </div>
        </div>
        <div class="col-md mb-5">
             <img src="/img/logo_bike_branco.svg" 
             class="mb-3" 
             alt="Logo Bike Registrada" 
             width="250"
             style="margin-left: -16px;">
          <div>
            <div>
              <strong>Fale com a gente!</strong>
            </div>
            <!-- <a
              class="d-block text-light"
              target="_blank"
              :href="`https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=${config.WHATSAPP_MENSAGEM_INICIAL}`"
            >{{formatacaoTelefone(config.WHATSAPP_NUMERO)}}</a> -->
            <a
              class="d-block text-light"
              target="_blank"
              href="tel:+556135224521"
            >+55 (61) 3522-4521</a>

            <a
              class="d-block text-light"
              href="mailto:contato@bikeregistrada.com.br?Subject=Classificados%20Bike%20Registrada"
              target="_top"
            >contato@bikeregistrada.com.br</a>

           <small>
              <div class="mt-3">
                SCS QUADRA 04 BL A LOTE 219/237, S/N,
                <div class="d-inline-block">1º Andar - Ed. Vera Cruz</div>
              </div>
              <div>Asa Sul, Brasília-DF | CEP: 70.304-913</div>
              <div>CNPJ: 19.654.238/0001-69</div>
            </small>
          </div>
          
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
import config from "../config";
import formatacaoTelefone from "../helpers/formatacaoTelefone";

export default {
  props: ["smoothScroll", "menuHeight"],
  data() {
    return {
      config,
      formatacaoTelefone
    };
  }
};
</script>


<style scoped>
.redes-sociais a {
  font-size: 32px;
}

.nav-link {
  color: rgba(255, 255, 255, 0.854) !important;
}
.nav-link:hover {
  text-decoration: underline;
}

footer {
  color: white;
}
</style>