<template>
  <div class="card">
    <div class="card-body text-left">
      <form>
        <div class="row">
          <div class="col-lg-3">
            <div class="form-group">
              <label>
                <img src="/img/ico_bike.svg" height="22" class="mr-2" />Marca ou modelo
              </label>
              <input type="text" class="form-control" placeholder="Digite a marca ou modelo" v-model="termoPesquisa" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>
                <img src="/img/capacete2.svg" height="22" class="mr-2" />Qual a modalidade?
              </label>

              <select class="form-control" :disabled="loading" v-model="modalidadeSelecionada">
                <option selected :value="undefined">Selecione</option>
                <option
                  v-for="(modalidade,idModalidade) in modalidades"
                  :key="'modalidade'+idModalidade"
                  :value="idModalidade"
                >{{modalidade}}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>
                <img src="/img/ico_pin.svg" height="22" class="mr-2" />Estado
              </label>

              <select class="form-control" :disabled="loading" v-model="estadoSelecionado">
                <option selected :value="undefined">Selecione</option>
                <option
                  v-for="(estado,idEstado) in estados"
                  :key="'modalidade'+idEstado"
                  :value="idEstado"
                >{{estado}}</option>
              </select>
            </div>
          </div>

          <div class="col-lg-3">
            <button
              class="btn btn-block btn-pesquisar mt-4"
              @click.prevent="onClickPesquisar"
              :disabled="loading"
            >
              <b-icon icon="search" class="mr-1"></b-icon>Pesquisar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../helpers/captureError";

import config from "../config";

export default {
  data() {
    return {
      estados: [],
      estadoSelecionado: undefined,
      modalidades: [],
      modalidadeSelecionada: undefined,
      termoPesquisa: undefined,
      loading: true
    };
  },
  created() {
    this.popularFiltro();
  },
  methods: {
    onClickPesquisar() {
      window.localStorage.removeItem("filtroPesquisa");
      this.$router.push(`/pesquisa?e=${this.estadoSelecionado || ''}&m=${this.modalidadeSelecionada || ''}&p=${this.termoPesquisa || ''}`);
    },
    async popularFiltro() {
      try {
        this.loading = true;

        var response = await axios.get(config.API_URL + "/anuncio/dadosFiltro");

        this.estados = response.data.uf;
        this.modalidades = response.data.tipoBike;
        this.loading = false;
      } catch (e) {
        captureError(e);
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
             "Não foi possível carregar os dados do filtro. Tente novamente mais tarde ou contate o suporte."
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

.card {
  border-radius: @border-radius;
  border-color: @primary-color;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
}

.btn-pesquisar {
  background-color: @primary-color;
  color: white;
  font-weight: 900;
  height: 52px;
  border-radius: @border-radius;
}

.btn-pesquisar:hover {
  color: white;
  opacity: 0.9;
}

label {
  color: @primary-color;
  font-weight: 700;
}
</style>