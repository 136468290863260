<template>
  <div class="bloco-modalidade text-right" :style="{backgroundImage: 'url('+foto+')'}">
    <div class="d-flex align-items-center descricao-container text-left">
      <div class="descricao">{{descricao}}</div>
    </div>

    <button class="btn btn-outline-light mr-3" @click="onClickModalidade">
      <img src="img/arrow-r.svg" height="18" />
    </button>
  </div>
</template>

<script>
export default {
  props: ["descricao","idModalidade","foto"],
  methods:{
    onClickModalidade(){
      window.localStorage.removeItem("filtroPesquisa");
      this.$router.push("/pesquisa?m="+this.idModalidade)
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

.bloco-modalidade {
  background-color: @gray-color;
  // background-image: url("/img/fundo_modalidade_urbano.jpg");
  background-size: cover;
  border-radius: @border-radius;
  height: 217px;
}

.descricao-container {
  height: 168px;
}
.descricao {
  color: white;
  font-size: 44px;
  font-weight: 900;
  line-height: 40px;
  margin-left: -5px;
}

.btn-outline-light:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .descricao-container {
    height: 112px;
  }

  .bloco-modalidade {
    height: 163px;
  }

  .descricao {
    font-size: 34px;
    line-height: 30px;
  }
}
</style>