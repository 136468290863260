<template>
  <div>
    <MenuSuperior :exibirBotoesAcao="true" :fixed="true"></MenuSuperior>
    <section id="main" class="menu-superior-fix">
      <div class="container text-left">
        <h1 class="title pt-5 mb-4 text-center text-md-left">
          <!-- Compre sua bike,
          <br />e deixe a procedência dela conosco!-->
          Compre ou venda sua bike
          <br />com segurança e procedência.
        </h1>

        <div class="row text-center text-md-left topicos-titulo">
          <div class="col-sm col-md-auto pr-2 mb-2">
            <img src="/img/ico_seguranca.svg" height="54" />
          </div>
          <div class="col-md mb-3">
            <div class="d-inline-block">
              <h3 class="subtitle">Maior Segurança</h3>
              <hr class="h-line" />
            </div>
            <p>
              Todas bicicletas anunciadas são
              <b>registradas</b> em nossa base de dados e passam por uma
              <b>validação de procedências</b>.
            </p>
          </div>
          <div class="col-sm col-md-auto pr-2 mb-2">
            <img src="/img/ico_comprador_certo.svg" height="54" />
          </div>
          <div class="col-md">
            <div class="d-inline-block">
              <h3 class="subtitle">Encontramos o Comprador Certo</h3>
              <hr class="h-line" />
            </div>
            <p>
              Com a rede de ciclistas da
              <b>Bike Registrada</b>, usamos nossa inteligência para encontrar o
              <b>comprador ideal</b> para sua bike anunciada.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="container barra-pesquisa">
      <BarraPesquisa></BarraPesquisa>
    </div>

    <BlocosModalidades></BlocosModalidades>
    <div v-if="loadingAnunciosDestaques" class="p-5">Carregando...</div>
    <BlocosAnuncios
     v-else
     titulo="Anúncios destaques"
      imagem="/img/bike_trilha.jpg"
       :items="anunciosDestaques"
       :destaques="true"
     ></BlocosAnuncios>

    <div v-if="loadingAnunciosRecentes" class="p-5">Carregando...</div>
    <BlocosAnuncios
      v-else
      titulo="Últimos anúncios"
      imagem="/img/bike_trilha.jpg"
      :items="anunciosRecentes"
    ></BlocosAnuncios>

    <!-- <BlocosAnuncios titulo="Companheira do dia-a-dia" imagem="/img/bike_floresta.jpg"></BlocosAnuncios>
    <BlocosAnuncios titulo="Para aventuras"  imagem="/img/bike_trilha.jpg"></BlocosAnuncios>
    <BlocosAnuncios titulo="Recomentados para você"  imagem="/img/bike_speed.jpg"></BlocosAnuncios>-->

    <BlocosConheca/>

    <BikeRegistradaNoBrasil/>
    <BlocosBlog/>

    <Rodape/>

    <!-- <WhatsAppFloatingButton :phone="config.WHATSAPP_NUMERO" :text="config.WHATSAPP_MENSAGEM_INICIAL"/> -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

import captureError from "../helpers/captureError";
import config from "../config";
import MenuSuperior from "../components/MenuSuperior";
import BarraPesquisa from "../components/BarraPesquisa";
import BlocosModalidades from "../components/BlocosModalidades";
import BlocosAnuncios from "../components/BlocosAnuncios";
import BlocosConheca from "../components/BlocosConheca";
import BlocosBlog from "../components/BlocosBlog";
import Rodape from '../components/Rodape';
import BikeRegistradaNoBrasil from '../components/BikeRegistradaNoBrasil';
//import WhatsAppFloatingButton from '../components/WhatsAppFloatingButton';

export default {
  components: {
    MenuSuperior,
    BarraPesquisa,
    BlocosModalidades,
    BlocosAnuncios,
    BlocosConheca,
    BlocosBlog,
    Rodape,
    BikeRegistradaNoBrasil,
    //WhatsAppFloatingButton
  },
  data() {
    return {
      config,
      loadingAnunciosRecentes: false,
      anunciosRecentes: [],
      anunciosDestaques: [],
      loadingAnunciosDestaques: false,
    };
  },
  created() {
    this.popularAnunciosDestaques(8);
    this.popularAnunciosRecentes();
  },
  methods: {
    async popularAnunciosDestaques(quantidade) {
      try {
        this.loadingAnunciosDestaques = true;

        var response = await axios.get(config.API_URL + "/anuncio/destaques?quantidade="+quantidade);

        this.anunciosDestaques = response.data;

        this.loadingAnunciosDestaques = false;
      } catch (e) {
        captureError(e);
        this.loadingAnunciosDestaques = false;

        Swal.fire({
          type: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar os anúncios Destaques. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async popularAnunciosRecentes() {
      try {
        this.loadingAnunciosRecentes = true;

        var response = await axios.get(config.API_URL + "/anuncio/recentes");

        this.anunciosRecentes = response.data;

        this.loadingAnunciosRecentes = false;
      } catch (e) {
        captureError(e);
        this.loadingAnunciosRecentes = false;

        Swal.fire({
          type: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar os anúncios recentes. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

#main {
  background-color: @dark-color;
  background-image: url("/img/fundo_home.jpg");
  background-size: cover;
  color: #f2f2f2;
}

.title {
  font-size: 53px;
  font-weight: 900;
  line-height: 75px;
}

.subtitle {
  font-size: 24px;
  font-weight: 700;
}

.h-line {
  border-color: @primary-color;
  margin-top: 10px;
  margin-top: 0;
  margin-bottom: 10px;
}

.barra-pesquisa {
  margin-top: -70px;
}

.topicos-titulo {
  padding-bottom: 120px;
}

@media (max-width: 768px) {
  .title {
    font-size: 36px;
    line-height: 42px;
  }

  .subtitle {
    font-size: 18px;
  }
}
</style>
