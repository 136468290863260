<template>
  <section class="blocos-blog py-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-8">
                <h2 class="text-left text-success mb-4 ">Confira nosso Blog</h2>
            </div>
            <div class="col-sm-4 d-none d-sm-block text-right">
                <a href="https://blog.bikeregistrada.com.br/" target="_blank" class="link-veja-mais">Veja mais</a>
            </div>
        </div>
      
      <div class="row small-gutters">
        <div class="col-12 col-sm-6 col-md mb-3">
          <BlocoBlog
            titulo="VEM PEDALAR: 16 BENEFÍCIOS DE ANDAR DE BIKE PARA A SAÚDE"
            urlImg="https://blog.bikeregistrada.com.br/wp-content/uploads/2018/05/Benef%C3%ADcios-de-andar-de-bike-1280x640.jpg"
            categoria="Saúde e bem-estar"
            dataPostagem="26/02/2020"
            link="https://blog.bikeregistrada.com.br/vem-pedalar-10-beneficios-de-andar-de-bike-para-a-saude/"
            ></BlocoBlog>
        </div>
        <div class="col-12 col-sm-6 col-md mb-3">
          <BlocoBlog
            titulo="15 DICAS DE BIKE PARA INICIANTES"
            urlImg="https://blog.bikeregistrada.com.br/wp-content/uploads/2017/11/10-dicas-de-bike-para-iniciantes.jpeg"
            categoria="Esporte e Fitness "
            dataPostagem="28/04/2020"
            link="https://blog.bikeregistrada.com.br/5-dicas-de-bike-para-iniciantes/"
            ></BlocoBlog>
        </div>
        <div class="col-12 col-sm-6 col-md mb-3">
          <BlocoBlog
            titulo="ESTÁ PENSANDO EM TROCAR DE BIKE?"
            urlImg="https://blog.bikeregistrada.com.br/wp-content/uploads/2019/09/MTBs-intermediarias-Trek-1280x640.jpg"
            categoria="Preparação e prática"
            dataPostagem="11/09/2019"
            link="https://blog.bikeregistrada.com.br/bikes-intermediarias-para-mtb/"
            ></BlocoBlog>
        </div>
        <div class="col-12 col-sm-6 col-md mb-3">
           <BlocoBlog
            titulo="CONHEÇA OS PRINCIPAIS TIPOS DE COBERTURAS DE SEGURO DE BIKE"
            urlImg="https://blog.bikeregistrada.com.br/wp-content/uploads/2018/07/tipos-de-seguro-de-bike.jpg"
            categoria="Segurança Online"
            dataPostagem="03/06/2020"
            link="https://blog.bikeregistrada.com.br/conheca-os-principais-tipos-de-coberturas-de-seguro-de-bike/"
            ></BlocoBlog>
        </div>
      </div>

       <div class="text-center d-block d-sm-none">
                <a href="https://blog.bikeregistrada.com.br/" target="_blank" class="link-veja-mais">Veja mais</a>
            </div>
    </div>
  </section>
</template>

<script>
import BlocoBlog from "./BlocoBlog";

export default {
  components: { BlocoBlog }
};
</script>

<style lang="less" scoped>
.blocos-blog {
  background-color: white;
}
</style>