<template>
 <div class="b-inline-block">
    <!-- <button
      v-if="!loggedUser"
      type="button"
      class="btn btn-outline-light"
      @click="login()"
    >Minha conta</button> -->

    <router-link
      v-if="!loggedUser"
      type="button"
      class="btn btn-outline-light2 text-white"
      :to="getRotaLogin()"
    >
    <i class="fa fa-user mr-1"></i>
    Entrar
    </router-link>

    <b-dropdown v-if="loggedUser" variant="outline-light" right >
       <template v-slot:button-content>
        <span class="logged-user-name">{{loggedUser}}</span>
      </template>

      <!-- <b-dropdown-item to="/dashboard/novo-anuncio">Novo anúncio</b-dropdown-item> -->
      <b-dropdown-item to="/dashboard/meus-anuncios">Meus anúncios</b-dropdown-item>
      <b-dropdown-item to="/dashboard/chat">Minhas negociações</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item to="/dashboard/minha-conta">Minha conta</b-dropdown-item>
      <b-dropdown-item-button @click.prevent="logout()">Sair</b-dropdown-item-button>
    </b-dropdown>

    <ModalLogin></ModalLogin>
  </div> 
</template>

<script>
import ModalLogin from "./ModalLogin";
import EventBus from "../../core/eventBus";
import auth from "../../core/auth";

export default {
  components: {
    ModalLogin
  },
  data() {
    return {
      loggedUser: undefined
    };
  },
  created() {
    this.updateLoggedUser();

    EventBus.$on("update-login", () => {
      this.updateLoggedUser();     
    });
  },
  methods: {
    updateLoggedUser() {
      this.loggedUser =  auth.getUserName();
    },
    logout() {
      auth.logout();

      this.acessarRota("/");
    },
    // login() {
    //   this.$bvModal.show("modal-login");
    // },
    acessarRota(rota) {
      if (this.$router.currentRoute.path != rota) {
        this.$router.push(rota);
      }
    },
    getRotaLogin() {
      return "/login?returnUrl=" + this.$route.path;
    }
  }
};
</script>

<style scoped>
@media (max-width: 576px) {
  .logged-user-name{
    max-width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      font-size: 13px;
      line-height: 11px;
  }
}
</style>