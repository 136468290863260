import * as Sentry from '@sentry/browser';

function captureError(error) {
    Sentry.configureScope(scope => {
        if (error.config) {
            scope.setExtra("url", error.config.url);
            scope.setExtra("method", error.config.method);
            scope.setExtra("request_data", error.config.data);
        }

        if (error.response) {
            scope.setExtra("response_data", error.response.data);
            scope.setExtra("status", error.response.status);
            scope.setExtra("headers", error.response.headers);
        }

        if (error.message) {
            scope.setExtra("message", error.message);
        }
        Sentry.captureException(error);
    });
}

export default captureError;