import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './filters';
import config from "./config"

//========================================================
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//========================================================

//Vue Mask
//======================================================
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

//VeeValidate
//======================================================
import { localize, extend, ValidationProvider, ValidationObserver, configure } from 'vee-validate';
import { required, email, min, confirmed, min_value } from 'vee-validate/dist/rules';
extend('confirmed', confirmed);
extend('min', min);
extend('min_value', min_value);
extend('required', required);
extend('email', email);
//Regras customizadas
import validacaoCPF from './helpers/validacaoCPF';
import validacaoCNPJ from './helpers/validacaoCNPJ';
extend('cpf', validacaoCPF);
extend('cnpj', validacaoCNPJ);

import pt_BR from 'vee-validate/dist/locale/pt_BR.json';
localize('pt_BR', pt_BR);
configure({
    classes: {
        // valid: 'is-valid',
        invalid: 'is-invalid'
    }
});
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

//Sentry
//======================================================
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

Sentry.init({
    dsn: 'https://d1cd281111e54b238a6ef8fdeb3aa927@o360584.ingest.sentry.io/5220104',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
});


//Vue-img carousel
import VueImg from 'v-img';
Vue.use(VueImg, {
    thumbnails: true,
});

// VueFileAgent
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
Vue.use(VueFileAgent);
import { SlickList, SlickItem } from 'vue-slicksort';
Vue.component('vfa-sortable-list', SlickList);
Vue.component('vfa-sortable-item', SlickItem);

//VueSelect
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

//Vue Analytics
import VueAnalytics from 'vue-analytics'
if (config.GOOGLE_ANALYTICS_ENABLED) {    
    Vue.use(VueAnalytics, {
        id: config.GOOGLE_ANALYTICS_ID,
        router
    });
}

//Facebook Pixel
if(config.FACEBOOK_PIXEL_ENABLED){
    !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  window.fbq('init', config.FACEBOOK_PIXEL_ID);
  window.fbq('track', 'PageView');
}

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')