<template>
  <div class="bloco-veja-mais text-right">
    <swiper :options="swiperOption" class="carousel">
      <swiper-slide>
        <div class="img-galeria" style="background-image: url('/img/fundo_modalidade_speed.jpg');"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="img-galeria" style="background-image: url('/img/fundo_modalidade_mountain.jpg');"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="img-galeria" style="background-image: url('/img/fundo_modalidade_cross.jpg');"></div>
      </swiper-slide>
      <swiper-slide>
        <div class="img-galeria" style="background-image: url('/img/fundo_modalidade_urbana.jpg');"></div>
      </swiper-slide>     
    </swiper>

    <div class="descricao">
      <div class="d-inline-block align-middle">
        <div class="texto">
          Veja mais
          <br />produtos
        </div>
      </div>
      <div class="d-inline-block ml-2 align-middle">
        <button class="btn btn-outline-light" @click.prevent="onClickVejaMais">
          <img src="/img/ico_mais.svg" height="18" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  props: ["imagem"],
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        // spaceBetween: 30,
        //   centeredSlides: true,
          loop: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
      }
    };
  },
  methods: {
    onClickVejaMais() {
      window.localStorage.removeItem("filtroPesquisa");
      this.$router.push("/pesquisa");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

.bloco-veja-mais {
  background-color: @dark-color;
  // background-image: url("/img/bike_floresta.jpg");
  // background-size: cover;
  border-radius: @border-radius;
  // height: 333px;
  // width: 361px;
  height: 100%;
  // padding: 8px;
  position: relative;
  min-height: 100px;
  overflow: hidden;
}

.carousel{
  // opacity:0.3;
  height: 100%;
}

.descricao {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index:99;
}

.btn-outline-light {
  padding-left: 9px;
  padding-right: 9px;
}

.btn-outline-light:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.texto {
  color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 2px;
}

.img-galeria {
  background-position: center;
  background-size: cover;
  min-height: 214px;
  height: 100%;
  width: 100%;
}
</style>