<template>
  <div v-if="dados" class="anuncio text-left d-flex flex-column shadow" :class="{'anuncio-destaque': dados.destaque && esconderLabelDestaque}">
    <div class="row no-gutters mb-auto">
      <div class="col-6 col-sm-12">

        <div v-if="dados.fotos && dados.fotos.length > 0" class="img-anuncio" :style="{backgroundImage: 'url('+dados.fotos[0]+')', backgroundColor: 'white'}"></div>
        <div v-else class="img-anuncio sem-imagem"></div>
      </div>
      <div class="col-6 col-sm-12">
        <div class="informacoes">
          <span v-if="dados.destaque && !esconderLabelDestaque" class="badge badge-pill badge-warning anuncio-destaque">Anúncio destaque</span>
          <div class="marca">{{dados.marca}}</div>
          <div class="modelo">{{dados.modelo}} {{dados.ano}}</div>
          <div class="preco fonte-numero ">{{dados.valorVenda | formatMoney}}</div>
          <div>
            <img src="/img/ico_pin_anuncio.svg" height="16" class="mr-2 align-middle" />
            <small class="align-middle regiao">{{dados.cidade}}, {{dados.ufSigla}}</small>
          </div>         
        </div>
      </div>
    </div>
    <div class="data-anuncio my-2">
      <hr class="mb-1 mt-0 d-none d-sm-block" />
      <small>Anunciado em: {{dados.dataCadastro | formatDate}}</small>
    </div>
    <!-- <button class="btn btn-block btn-anuncio" @click.prevent="onClickVerAnuncio">Ver anúncio</button> -->
    <!-- <a :href="'/anuncio/'+dados.id" class="btn btn-block btn-anuncio">Ver anúncio</a> -->
    <router-link :to="'/anuncio/'+dados.id" class="btn btn-block btn-anuncio">Ver anúncio</router-link>
  </div>
</template>

<script>
export default {
  props:['url', 'dados', 'esconderLabelDestaque'],
  methods:{
    onClickVerAnuncio(){
      this.$emit('ver-anuncio', this.dados?.id);
    }
  }
}
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

.img-anuncio {
  // background-image: url("/img/empty_img.png");
  background-position: center center;
  background-size: cover;
  background-color: @light-gray-color;
  width: 100%;
  height: 150px;
  border-top-left-radius: @border-radius;
  border-top-right-radius: @border-radius;
}

.img-anuncio.sem-imagem{
  background-image: url("/img/empty_img2.jpg");
}

.anuncio {
  // width:234px;
  background-color: white;
  border-radius: @border-radius;
  // box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
  height: 100%;
}

.anuncio.anuncio-destaque {
  // width:234px;
  background-color: @destaque-bg-color;
}

.informacoes {
  padding: 12px;
  padding-bottom: 0;
  padding-top: 8px;
}
.marca {
  color: #858585;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
}

.modelo {
  color: @default-text-color;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}

.preco {
  color: @primary-color;
  font-weight: 700;
  font-size: 24px;
}

.data-anuncio {
  line-height: 12px;
    padding-left: 12px;
}



.btn-anuncio {
  background-color: @primary-color;
  color: white;
  font-weight: 700;
  font-size: 14px;
  // height: 43px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.btn-anuncio:hover {
  color: white;
  opacity: 0.9;
}

@media (max-width: 576px) {
  .img-anuncio {
    border-top-right-radius: 0;
  }

  .marca {
    font-size: 12px;
  }

  .modelo {
    font-size: 16px;
  }

  .preco {
    font-size: 18px;
  }

  // .regiao{
  //   font-size: 12px;
  // }

  .btn-anuncio{
    height: auto;
  }
}

.bloco-anuncio-destaque{
  background-color: @secondary-color;
}

</style>