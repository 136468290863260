import EventBus from './eventBus';
import axios from "axios";
import moment from 'moment';

export default {
    setUserInfo(userInfo) {
        if (userInfo) {
            var v = JSON.stringify(userInfo);
            window.localStorage.setItem("UserInfo", v);

            var token = userInfo.token;
            this.applyAuthorizationHeader(token);
        }

        EventBus.$emit('update-login');
    },
    getUserInfo() {
        var userInfo = {};

        var value = window.localStorage.getItem("UserInfo");
        if (value) {
            try {
                userInfo = JSON.parse(value);
            } catch (e) {
                //console.error(e);
            }
        }
        return userInfo;
    },
    getUserName() {
        var userName = undefined;
        var userInfo = this.getUserInfo();
        if (userInfo && userInfo.token) {
            userName = userInfo.nome ? userInfo.nome.split(" ")[0] : "Usuário";
        }
        return userName;
    },
    logout() {
        window.localStorage.removeItem("UserInfo");

        this.applyAuthorizationHeader(undefined);
        this.logoutFB();

        EventBus.$emit('update-login');
    },
    logoutFB() {
        if (window["FB"]) {
            try {
                window.FB.logout();
            } catch (e) {
                //console.log("Facebook logout", e)
            }
        }
    },
    applyAuthorizationHeader(token){
        axios.defaults.headers.common['Authorization'] =  token ? `Bearer ${token}` : '';
    },
    isLoggedIn(){
        var userInfo = this.getUserInfo();
        var token = userInfo?.token;
        
        if (!token) { return false; }

        var dtExpiry = userInfo?.dataExpiracaoToken;

        if (!dtExpiry) { return false; }

        var expiryTime = moment(dtExpiry).utc().valueOf();

        //Verifica se o token ainda não expirou
        if (token && dtExpiry && moment.utc().valueOf() > expiryTime) {
            return false;
        }

        return !!token;
    }
}