<template>
    <section class="container mb-5 mt-5" v-if="items && items.length">
      <h2 class="text-left mb-4" :class="{'texto-destaque': destaques}">{{titulo}}</h2>
      <div class="row blocos-anuncios small-gutters">
        <!-- <div class="col-12 col-sm-6 col-md mb-3">
          <BlocoAnuncio :dados="{marca:'CALOI'}" ></BlocoAnuncio>
        </div>
        <div class="col-12 col-sm-6 col-md  mb-3">
          <BlocoAnuncio :destaque="true" ></BlocoAnuncio>
        </div>
        <div class="col-12 col-sm-6 col-md  mb-3">
          <BlocoAnuncio ></BlocoAnuncio>
        </div>
        <div class="col-12 col-sm-6 col-md  mb-3">
          <BlocoAnuncio ></BlocoAnuncio>
        </div> -->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3  mb-3"
         v-for="item in items" :key="item.id">
          <BlocoAnuncio :dados="item" :esconderLabelDestaque="destaques"></BlocoAnuncio>
        </div>
        <div class="d-none2 d-lg-block2 col-12 col-sm-6 col-md-4 col-lg-3  mb-3" v-if="!destaques">
          <!-- <BlocoVejaMais :imagem="imagem"></BlocoVejaMais> -->
          <BlocoVejaMaisAnimado/>
        </div>
        
      </div>
       <!-- <div class="d-lg-none">
          <a href="#" class="link-veja-mais">Veja mais</a>
        </div> -->
    </section>
</template>

<script>
import BlocoAnuncio from "./BlocoAnuncio";
//import BlocoVejaMais from "./BlocoVejaMais";
import BlocoVejaMaisAnimado from "./BlocoVejaMaisAnimado";

export default {
    props:['titulo','imagem', 'items', 'destaques'],
    components:{
      BlocoAnuncio,
      //BlocoVejaMais,
      BlocoVejaMaisAnimado
    }
}
</script>