function formatacaoTelefone(telefone) {

    if (!telefone) { return ""; }

    var telefoneFormatado = "";

    var startIdx = 0;
    if (telefone.length == 13 || telefone.length == 12) {
        telefoneFormatado += '+' + telefone.substr(0, 2) + ' ';
        startIdx += 2;
    }

    var numero = telefone.substr(startIdx);

    if (numero.length === 9 + 2) {
        telefoneFormatado += `(${numero.substr(0, 2)}) ${numero.substr(2, 5)}-${numero.substr(7)}`;
    } else {
        telefoneFormatado += `(${numero.substr(0, 2)}) ${numero.substr(2, 4)}-${numero.substr(6)}`;
    }

    return telefoneFormatado;
}

export default formatacaoTelefone;