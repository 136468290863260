import configProduction from "./config.production.js";

let config = {
  //API_URL: "http://localhost:57146/api",
  API_URL: "https://classificados-dev.azurewebsites.net/api",
  // API_URL: "https://classificados.bikeregistrada.com.br/api",

  IUGU_ACCOUNT: "84191965AAB94AA68302F424223286DF",
  IUGU_TEST_MODE: true, //true -> Ambiente Dev; false -> Ambiente produção

  GOOGLE_ANALYTICS_ENABLED: false,
  GOOGLE_ANALYTICS_ID: "UA-43794009-4",

  FACEBOOK_PIXEL_ID: "246369472437886",
  FACEBOOK_PIXEL_ENABLED: false,

  FACEBOOK_APP_ID: "300436080139400",
  WHATSAPP_NUMERO: "5561996884521",
  WHATSAPP_MENSAGEM_INICIAL:
    "Olá, acessei o site Classificados Bike Registrada e gostaria de mais informações.",

  PLANO_DESTAQUE_ID: "24A51944-414B-4C00-A2BF-FA7D5814BE87",

  ORDEM_ANUNCIOS_MAIS_RECENTES: 0,
  ORDEM_ANUNCIOS_MENOR_PRECO: 1,
  ORDEM_ANUNCIOS_MAIOR_PRECO: 2,

  VELOCIDADES_CAMBIO_DIANTEIRO: 3,
  VELOCIDADES_CAMBIO_TRASEIRO: 12,

  CONDICAO_BIKE_USADA: 0,
  CONDICAO_BIKE_NOVA: 1,

  PLANO_ANUNCIO_PREMIUM: "ab0d90e5-88e6-42e1-950f-60f267bb3a0d",
  PLANO_ANUNCIO_BASICO: "6d1e5cc3-492a-487e-b34a-5384af67432f",
  PLANO_ANUNCIO_LANCAMENTO: "CF9065EB-0404-4513-98A7-29E99DD416DE",

  DATA_PROMOCAO_PLANO_GRATIS: "01/09/2020",

  TIPO_CONTA_PESSOA_FISICA: "fisica",
  TIPO_CONTA_PESSOA_JURIDICA: "juridica",

  TAMANHOS_QUADRO: [
    { value: "S", text: "S" },
    { value: "S/M", text: "S/M" },
    { value: "M", text: "M" },
    { value: "M/L", text: "M/L" },
    { value: "L", text: "L" },
    { value: "L/XL", text: "L/XL" },
    { value: "XL", text: "XL" },
    { value: "XL/XXL", text: "XL/XXL" },
    { value: "XXL/XXXL", text: "XXL/XXXL" },
    { value: "XXXL", text: "XXXL" },
    { value: "TU", text: "Tamanho único" },
  ],

  FOTOS_ANUNCIO_FILE_TYPES: "image/jpg, image/jpeg, image/png",

  URL_TERMOS_USO:
    "https://drive.google.com/file/d/1pm-o7I2f12vCfUSD8DqMM3bkWGe7U5IP/view?usp=sharing",

  URL_INSTAGRAM: "https://instagram.com/bikeregistrada",
  URL_FACEBOOK: "https://fb.com/bikeregistrada",
  URL_YOUTUBE: "https://youtube.com/bikeregistrada",
  URL_TWITTER: "https://twitter.com/bikeregistrada",

  TEMPO_SEGUNDOS_ENVIAR_SMS_CODIGO_VALIDACAO_CELULAR: 30,
};

if (process.env.VUE_APP_ENV == "production") {
  config = Object.assign(config, configProduction);
}

export default config;
