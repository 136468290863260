<template>
  <section class="container mb-5 mt-5">
    <h2 class="text-left mb-4">Conheça também</h2>
    <div class="row small-gutters">
      <div class="col-12 col-sm-6 col-lg-3 mb-3">
        <!-- <BlocoConheca texto="Registro Bike Registrada" url="https://bikeregistrada.com.br/"></BlocoConheca> -->
        <div class="card border-radius shadow h-100">
          <div class="card-body text-left">
            <h3 class="mb-0 font-weight-bold text-primary">Registro</h3>
            <b>Bike Registrada</b>
            <p class="mt-2">Quem além de você sabe que sua bike é sua?</p>
            <p>Registre sua bike e curta o pedal tranquilo!</p>
          </div>
          <div class="card-footer text-muted p-0">
            <a
              class="btn btn-secondary btn-veja-mais btn-block"
              href="https://bikeregistrada.com.br/?tag_origem=banner-home-classificados"
              target="_blank"
            >Veja mais</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 mb-3">
        <!-- <BlocoConheca texto="Seguro Bike Registrada" url="https://seguro.bikeregistrada.com.br/"></BlocoConheca> -->

        <div class="card border-radius shadow h-100">
          <div class="card-body text-left">
            <h3 class="mb-0 font-weight-bold text-primary">Seguro</h3>
            <b>Bike Registrada</b>
            <p class="mt-2">On-line e você paga mês a mês.</p>
            <p>Simples e prático assim!</p>
          </div>
          <div class="card-footer text-muted p-0">
            <a
              class="btn btn-secondary btn-veja-mais btn-block"
              href="https://seguro.bikeregistrada.com.br/?tag_origem=banner-home-classificados"
              target="_blank"
            >Veja mais</a>
          </div>
        </div>
      </div>
         <div class="col-12 col-sm-6 col-lg-3 mb-3">
        <!-- <BlocoConheca texto="Financiamento Bike Registrada(Em breve)" url="#"></BlocoConheca> -->
        <div class="card border-radius shadow h-100">
          <div class="card-body text-left">
            <h3 class="mb-0 font-weight-bold text-primary">Financiamento</h3>
            <b>Bike Registrada</b>
            <p class="mt-2">Linha de crédito para te ajudar a tornar a bike dos seus sonhos uma realidade.</p>
          </div>
          <div class="card-footer text-muted p-0">
            <button
              disabled
              class="btn btn-secondary btn-veja-mais btn-block"
              targe="_blank"
            >Em breve</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-lg-3 mb-3">
        <!-- <BlocoConheca texto="Biker Club (Em breve)" url="#"></BlocoConheca> -->
        <div class="card border-radius shadow h-100">
          <div class="card-body text-left">
            <h3 class="mb-0 font-weight-bold text-primary">Biker Club</h3>
            <b>Bike Registrada</b>
            <p class="mt-2">Um clube de vantagens para você aproveitar ainda mais o mundo do pedal.</p>
          </div>
          <div class="card-footer text-muted p-0">
            <button
              disabled
              class="btn btn-secondary btn-veja-mais btn-block"
              targe="_blank"
            >Em breve</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import BlocoConheca from "./BlocoConheca";

export default {
  //components: { BlocoConheca }
};
</script>

<style scoped>
.btn-veja-mais {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 700;
  font-size: 14px;
}
</style>
