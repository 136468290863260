import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../core/auth'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: Home
},
{
    path: '/pesquisa',
    component: () =>
        import( /* webpackChunkName: "pesquisa" */ '../views/Pesquisa.vue')
},
{
    path: '/anuncio/:idAnuncio',
    component: () =>
        import( /* webpackChunkName: "produto" */ '../views/Anuncio.vue')
},
{
    path: '/faq',
    component: () =>
        import( /* webpackChunkName: "ajuda" */ '../views/Faq.vue')
},
{
    path: '/criar-conta',
    component: () =>
        import( /* webpackChunkName: "criar-conta" */ '../views/CriarConta.vue')
},
{
    path: '/login',
    component: () =>
        import( /* webpackChunkName: "login" */ '../views/Login.vue')
},
{
    path: '/recuperar-senha',
    component: () =>
        import( /* webpackChunkName: "recuperar-senha" */ '../views/RecuperarSenha.vue')
},
{
    path: '/definir-senha',
    component: () =>
        import( /* webpackChunkName: "definir-senha" */ '../views/DefinirSenha.vue')
},
{
    path: '/dashboard',
    meta: { requiresAuth: true },
    component: () =>
        import( /* webpackChunkName: "Dashboard" */ '../views/Dashboard/Dashboard.vue'),
    children: [
        {
            path: '/',
            component: () =>
                import( /* webpackChunkName: "minha-conta" */ '../views/Dashboard/Home.vue')
        },
        {
            path: 'minha-conta',
            component: () =>
                import( /* webpackChunkName: "minha-conta" */ '../views/Dashboard/MinhaConta.vue')
        },
        {
            path: 'meus-anuncios',
            component: () =>
                import( /* webpackChunkName: "meus-anuncios" */ '../views/Dashboard/Anuncios/MeusAnuncios.vue')
        },
        {
            path: 'escolher-plano/:idAnuncio',
            component: () =>
                import( /* webpackChunkName: "escolher-plano" */ '../views/Dashboard/Anuncios/EscolherPlanoDinamico.vue')
        },
        {
            path: 'anuncio',
            component: () =>
                import( /* webpackChunkName: "salvar-anuncio" */ '../views/Dashboard/Anuncios/SalvarAnuncio.vue')
        },
        {
            path: 'anuncio/:idAnuncio',
            component: () =>
                import( /* webpackChunkName: "salvar-anuncio" */ '../views/Dashboard/Anuncios/SalvarAnuncio.vue')
        },
        {
            path: 'chat',
            component: () =>
                import( /* webpackChunkName: "chat" */ '../views/Dashboard/Anuncios/Chat.vue')
        },
        {
            path: 'checkout-plano/:idAnuncio/:idPlanoAnuncio',
            component: () =>
                import( /* webpackChunkName: "chat" */ '../views/Dashboard/CheckoutPlano.vue')
        },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

//Verificador de autenticação
router.beforeEach((to, from, next) => {

    //Verifica se preicsa de autenticação para acessar a rota
    if (to.matched.some(record => record.meta?.requiresAuth) && !auth.isLoggedIn()) {

        next({ path: '/login', query: { returnUrl: to.fullPath } });

    } else {
        next();
    }
});

export default router