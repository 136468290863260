<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="mt-2" @submit.prevent="handleSubmit(loginBikeRegistrada)">
        <div class="form-group">
          <ValidationProvider name="e-mail" rules="required" v-slot="{ errors, classes }">
            <input
              v-model="emailLogin"
              type="email"
              class="form-control"
              :class="classes"
              placeholder="Digite seu e-mail"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <ValidationProvider name="senha" rules="required" v-slot="{ errors, classes }">
            <input
              v-model="senhaLogin"
              type="password"
              class="form-control"
              :class="classes"
              placeholder="Digite sua senha"
              @keyup.enter="loginBikeRegistrada()"
            />
            <span class="invalid-feedback">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <button type="submit" class="btn btn-primary btn-block" :disabled="loadingLogin">
          <span v-if="!loadingLogin">Fazer login e continuar</span>
          <span v-if="loadingLogin">Por favor aguarde...</span>
        </button>

        <!-- <div class="my-2 text-center">ou</div> -->
      </form>
    </ValidationObserver>

    <!-- <facebook-login
      v-show="!loadingLoginFb"
      class="btn-fb"
      loginLabel="Fazer login com o facebook"
      logoutLabel="Fazer login com o facebook"
      :loginOptions="{scope: 'email, public_profile' }"
      :appId="config.FACEBOOK_APP_ID"
      @login="loginFacebook"
    ></facebook-login>
    <button v-show="loadingLoginFb" class="btn btn-block btn-fb" disabled>Por favor aguarde...</button>-->

    <!-- <hr> -->
    <div class="text-center mt-3">
      <router-link to="/recuperar-senha" style="font-size:14px;">Esqueci minha senha</router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import facebookLogin from "facebook-login-vuejs";
import Swal from "sweetalert2";

import config from "../../config";
import auth from "../../core/auth";
import captureError from "../../helpers/captureError";

export default {
  components: {
    //facebookLogin
  },
  data() {
    return {
      config,

      emailLogin: undefined,
      senhaLogin: undefined,
      loadingLogin: false,
      loadingLoginFb: false,

      qtdErroLogin: 0,
    };
  },
  created() {},
  methods: {
    async loginBikeRegistrada() {
      this.loadingLogin = true;

      try {
        var data = {
          email: this.emailLogin,
          senha: this.senhaLogin,
        };
        var response = await axios.post(`${config.API_URL}/auth/login`, data);

        //console.log(response);

        //if (response.data.sucesso) {
        //auth.setUserInfo(response.data.cliente);

        auth.setUserInfo(response.data);

        this.$emit("login-success");
        // } else {
        //   var msg = response.data.mensagem;

        //   Swal.fire({
        //     title: msg,
        //     text: "",
        //     icon: "error",
        //     confirmButtonText: "Ok"
        //   });
        // }
      } catch (e) {
        //console.log(e);

        if (e.response && e.response.data && e.response.data.invalido) {
          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "O e-mail ou senha estão incorretos.",
          });

          this.qtdErroLogin++;
          if (this.qtdErroLogin >= 2) {
            this.$router.push("/recuperar-senha");
          }
        } else {
          captureError(e);
          Swal.fire({
            title: "Não foi possível fazer o login",
            text: "Tente novamente mais tarde ou contate o suporte.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
      this.loadingLogin = false;
    },
    async loginFacebook(args) {
      //console.log("loginFacebook");
      //console.log(args);

      try {
        //Se fechou a tela de login
        var status = args?.response?.status;
        if (status == "unknown") {
          return;
        }

        var idFacebook = args?.response?.authResponse?.userID;

        if (!idFacebook) {
          throw new Error("Não foi possível obter o userID do facebook");
        }
        this.loadingLoginFb = true;

        var dadosFb = await this.obterDadosFacebook();
        // console.log("Dados")
        // console.log(dadosFb);

        var response = await axios.post(
          `${config.API_URL}/auth/loginfb?idFacebook=${idFacebook}`
        );

        if (response.data.sucesso) {
          if (dadosFb && dadosFb.name) {
            response.data.cliente.nome = dadosFb.name;
          }
          if (dadosFb && dadosFb.email) {
            response.data.cliente.email = dadosFb.email;
          }
          auth.setUserInfo(response.data.cliente);

          this.$emit("login-success");
        } else {
          var msg = response.data.mensagem;
          captureError(msg);

          Swal.fire({
            title: msg,
            text: "",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      } catch (e) {
        captureError(e);

        Swal.fire({
          title: "Não foi possível fazer o login com o facebook",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
      this.loadingLoginFb = false;
    },
    obterDadosFacebook() {
      if (!window["FB"]) {
        return undefined;
      }

      return new Promise((resolve, reject) => {
        window.FB.api(
          "/me?fields=id,name,email",
          function onFacebookLoginResponse(fbResponse) {
            if (fbResponse) {
              resolve(fbResponse);
            } else {
              reject();
            }
          }
        );
      });
    },
  },
};
</script>

<style>
.btn-fb {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.btn-fb button {
  width: 100%;
  border-radius: 0.25rem;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.btn-fb button img {
  top: 8px;
  left: calc(50% - 110px);
  width: 22px;
}

.btn-fb button .spinner {
  display: none !important;
}
</style>