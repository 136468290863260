<template>
  <div class="post text-left d-flex flex-column shadow">
    <div class="row no-gutters mb-auto">
      <div class="col-5 col-sm-12">
        <div class="img-post" :style="{backgroundImage: 'url('+urlImg+')'}"></div>
      </div>
      <div class="col-7 col-sm-12">
        <div class="informacoes">         
          <div class="categoria">{{categoria}}</div>
          <div class="titulo mb-3">{{titulo}} </div>
          <!-- <small>Postado em: {{dataPostagem}}</small> -->
        </div>
      </div>
    </div>
    <a :href="link" target="_blank" class="btn btn-block btn-post">Ler matéria</a>
  </div>
</template>

<script>
export default {
  props:['destaque', 'urlImg', 'categoria', 'titulo', 'dataPostagem', 'link']
}
</script>

<style lang="less" scoped>
@import "../styles/variaveis";

.img-post {
  // background-image: url("/img/noticia_blog.jpg");
  // background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 120px;
  border-top-left-radius: @border-radius;
  border-top-right-radius: @border-radius;
}

.post {
  background-color: white;
  border-radius: @border-radius;
  // box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.11);
  height: 100%;
}

.informacoes {
  padding: 12px;
  padding-bottom: 0;
  padding-top: 8px;
}

.categoria {
  color: #858585;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}

.titulo {
  font-style: italic;
  color: @primary-color;
  font-weight: 700;
  font-size: 16px;
}

.data-post {
  line-height: 12px;
  padding-left: 12px;
}


.btn-post {
  background-color: @primary-color;
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.btn-post:hover {
  color: white;
  opacity: 0.9;
}

@media (max-width: 576px) {
  .img-post {
    border-top-right-radius: 0;
  }

  .btn-anuncio{
    height: auto;
  }
}
</style>