<template>
    <section class="container mt-5">
      <div class="row blocos-modalidades small-gutters">
        <div class="col-6 col-lg-3 mb-3">
          <BlocoModalidade 
            descricao="Speed"
            idModalidade="4f8c232b-988a-4ace-becd-7717d231a323"
            foto="/img/fundo_modalidade_speed.jpg" />
        </div>
        <div class="col-6 col-lg-3 mb-3">
          <BlocoModalidade 
          descricao="Montain Bike"
          idModalidade="3d125ebe-0b8f-4168-bfef-429d5070a3b2"
           foto="/img/fundo_modalidade_mountain.jpg" />
        </div>
        <div class="col-6 col-lg-3 mb-3">
          <BlocoModalidade
          descricao="Bmx Cross"
          idModalidade="57815a09-b3c8-4e4a-9799-5552aff18be1"
           foto="/img/fundo_modalidade_cross.jpg"/>
        </div>
        <div class="col-6 col-lg-3 mb-3">
          <BlocoModalidade
           descricao="Urbana"
           idModalidade="4d80d626-03a6-4cdc-adf0-baecc91e0f3c"
            foto="/img/fundo_modalidade_urbana.jpg" />
        </div>
      </div>
    </section>
</template>

<script>
import BlocoModalidade from "./BlocoModalidade";

export default {
    components:{ BlocoModalidade }
}
</script>