<template>
  <header class="navbar navbar-dark" :class="{'fixed-top': fixed }">
    <div class="container px-0">
      <router-link to="/">
        <img src="/img/logo_bike.svg" height="40" />
      </router-link>

      <ul class="nav">
        <li class="nav-item d-none d-md-block" v-if="exibirBotoesAcao">
          <button class="nav-link btn btn-primary btn-sm mr-3" @click="onClickQueroComprar">Quero comprar</button>
        </li>
        <li class="nav-item d-none d-md-block" v-if="exibirBotoesAcao">
          <router-link
            class="nav-link btn btn-success btn-sm mr-3"
            to="/dashboard/meus-anuncios"
          >Quero vender</router-link>
        </li>
        <li class="nav-item">
          <LoggedUserButton></LoggedUserButton>
        </li>
      </ul>
    </div>

    <div class="row small-gutters mx-0 w-100 mt-2 d-md-none" v-if="exibirBotoesAcao">
      <div class="col-6">
        <button class="btn btn-primary btn-sm btn-block" @click="onClickQueroComprar">Quero comprar</button>
      </div>
      <div class="col-6">
        <router-link
          class="btn btn-success btn-sm btn-block"
          to="/dashboard/meus-anuncios"
        >Quero vender</router-link>
      </div>
    </div>
  </header>
</template>

<script>
import LoggedUserButton from "./Login/LoggedUserButton";
export default {
  props: ["fixed", "exibirBotoesAcao"],
  components: {
    LoggedUserButton
  },
  methods: {
    onClickQueroComprar() {
      window.localStorage.removeItem("filtroPesquisa");
      this.$router.push(`/pesquisa`);
    }
  }
};
</script>
