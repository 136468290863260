<template>
  <b-modal id="modal-login" title="Faça login em sua conta" size="md" variant="dark" hide-footer>
    <div>    
      <strong>Conta Bike registrada</strong>  
      <LoginForm @login-success="onLoginSuccess"/>
    </div>
    <div v-if="!esconderNovaConta">
      <hr/>
      <strong>Ainda não possui uma conta?</strong>
      <router-link to="/criar-conta" class="btn btn-outline-primary btn-block mt-2">Criar uma nova conta</router-link>
    </div>
  </b-modal>
</template>

<script>
import LoginForm from "./LoginForm";

export default {
  props:["esconderNovaConta"],
  components: {
    LoginForm
  },
  data() {
    return {};
  },
  methods: {
    onLoginSuccess() {
      //Esconder modal
      this.$bvModal.hide("modal-login")
      this.$emit('login-success');

       //Reload
      window.location.reload();
    }
  }
};
</script>